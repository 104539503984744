import { useState } from "react"
import { Menu } from "../../index.d"
import { classNames } from "../../helpers"

interface MenuSectionProps {
  selectedLang: string
  menus: Menu[]
}

const MenuSections = (props: MenuSectionProps) => {
  const { menus, selectedLang } = props

  const [selectedCategory, setSelectedCategory] = useState("")

  return (
    <div>
      <div className="sm:hidden">
        <label htmlFor="tabs" className="sr-only">
          Select a tab
        </label>
        {/* Use an "onChange" listener to redirect the user to the selected tab URL. */}
        <select
          id="tabs"
          name="tabs"
          className="block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
          defaultValue={selectedCategory}
        >
          {menus.map((menu) => {
            if (menu.menuItems.length === 0) {
              return null
            }
            return <option key={menu.category.id}>{menu.category.name[selectedLang]}</option>
          })}
        </select>
      </div>
      <div className="hidden sm:block overflow-x-auto overscroll-contain">
        <div className="border-b border-gray-200">
          <nav className="-mb-px flex space-x-8" aria-label="Tabs">
            {menus.map((menu) => {
              if (menu.menuItems.length === 0) {
                return null
              }
              return (
                <a
                  key={menu.category.id}
                  onClick={() => setSelectedCategory(menu.category.id)}
                  href={`#${menu.category.id}`}
                  className={classNames(
                    menu.category.id === selectedCategory
                      ? "border-indigo-500 text-indigo-600"
                      : "border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300",
                    "whitespace-nowrap py-4 px-1 border-b-2 font-medium text-sm"
                  )}
                  aria-current={menu.category.id === selectedCategory ? "page" : undefined}
                >
                  {menu.category.name[selectedLang]}
                </a>
              )
            })}
          </nav>
        </div>
      </div>
    </div>
  )
}

export default MenuSections
